import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { PlannedOutage, PlannedOutageModel } from '../models/plannedOutage';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class PlannedOutageService extends ApiService {
  public plannedOutagesLoading$ = new BehaviorSubject(false);
  public isPlannedOutageLoading$ = new BehaviorSubject(false);
  private readonly _logger = new LoggingService(
    'Dashboard',
    'PlannedOutageService'
  );

  public getPlannedOutage(uuid: string): Observable<PlannedOutage> {
    if (this.isPlannedOutageLoading$.getValue()) {
      return;
    }
    this.isPlannedOutageLoading$.next(true);

    return this.http
      .get<PlannedOutageModel>(`${this.apiUrl}/planned-outages/${uuid}`)
      .pipe(
        map((resp: PlannedOutageModel) => new PlannedOutage(resp)),
        catchError((err) =>
          this.handleError('Unable to load planned outage', err)
        ),
        finalize(() => this.isPlannedOutageLoading$.next(false))
      );
  }

  public getPlannedOutages(
    start: string,
    end: string
  ): Observable<PlannedOutage[]> {
    if (this.plannedOutagesLoading$.getValue()) {
      return;
    }
    this.plannedOutagesLoading$.next(true);

    let params = ApiService.setHttpParam('startDate', start);
    if (end) {
      params = ApiService.setHttpParam('endDate', end, params);
    }

    return this.http
      .get<PlannedOutageModel[]>(`${this.apiUrl}/planned-outages/`, { params })
      .pipe(
        map((resp: PlannedOutageModel[]) =>
          resp.map(
            (plannedOutageModel) => new PlannedOutage(plannedOutageModel)
          )
        ),
        catchError((err) =>
          this.handleError('Unable to fetch planned outages', err)
        ),
        finalize(() => this.plannedOutagesLoading$.next(false))
      );
  }

  public getSitePlannedOutages(uniqueId: number | string, startDate: Date, endDate: Date): Observable<PlannedOutageModel[]> {
    this.plannedOutagesLoading$.next(true);
    const dateRange = `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    return this.http.get<PlannedOutageModel[]>(`${this.apiUrl}/planned-outages/site/${uniqueId}?${dateRange}`).pipe(
      catchError((err) => {
        return this.handleError(`Unable to load site ${uniqueId} planned outages.`, err);
      }),
      finalize(() => this.plannedOutagesLoading$.next(false))
    );
  }

  public deletePlannedOutage(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/planned-outages/${uuid}`);
  }

  savePlannedOutage(
    plannedOutage: PlannedOutageModel
  ): Observable<PlannedOutageModel> {
    this._logger.info(
      `savePlannedOutage() given ${JSON.stringify(plannedOutage, null, 2)}`
    );
    return plannedOutage.uuid
      ? this.http.put<PlannedOutageModel>(
          `${this.apiUrl}/planned-outages/${plannedOutage.uuid}`,
          plannedOutage
        )
      : this.http.post<PlannedOutageModel>(
          `${this.apiUrl}/planned-outages`,
          plannedOutage
        );
  }
}
