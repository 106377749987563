<div class="source-comparison-chart__container chart-container relative curtailment-chart !p-0">
    <div class="flex gap-[20px]">
      <div  class="text-xl mb-10">Curtailment Summary</div>
      <mat-form-field appearance="outline">
        <mat-label>Year</mat-label>
        <mat-select [formControl]="yearControl"  (selectionChange)="selectYear($event)">
          <mat-option *ngFor="let year of years" [value]="year" >
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
  <div class="chart-container !p-0 mt-[20px]" [hidden]="datasets.get(this).length === 0" >
    <canvas id="{{ chartId }}" width="500"></canvas>
  </div>
     <div class="legend-container flex-container items-center !pt-0" [hidden]="datasets.get(this).length === 0">
      <div class="left">
        <ng-container *ngFor="let d of datasets.get(this)">
          <div class="legend-item" [ngClass]="{ toggled: d.data.length === 0 }" (click)="toggleDataset(d)">
            <span class="box" [style.backgroundColor]="d.borderColor"></span>
            <span>{{ d.label }}</span>
          </div>
        </ng-container>
      </div>
      <div class="right curt-export-btn">
        <app-icon-button
        name="Export Table"
        iconName="table/export"
        buttonType="text"
  
      ></app-icon-button>
      </div>
    </div>
    <app-no-data
    *ngIf="datasets.get(this).length === 0"
    [title]="'No Source Comparison Data'"
    [summary]="'Source Comparison chart data will appear here'"
  >
  </app-no-data>
  <app-loading *ngIf="loading"></app-loading>
  </div>