import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_X_TICK_COLOR_AND_FONT,
  DEFAULT_TICK_COLOR,
} from 'src/app/shared/models/charts/default-chart-options';
import { ChartOptions } from 'chart.js';

export const DEFAULT_CURTAILMENT_CHART_OPTIONS: ChartOptions<'bar'> = {
  ...DEFAULT_CHART_OPTIONS,
  skipNull: false,
  maintainAspectRatio: true,
  aspectRatio: 6.5,
  scales: {
    x: {
      title: {
        display: true,
      },
      type: 'linear' as const,
      ticks: {
        callback: function (val: string) {
          const monthMapping: { [key: string]: string } = {
            '1': 'Jan',
            '2': 'Feb',
            '3': 'Mar',
            '4': 'Apr',
            '5': 'May',
            '6': 'Jun',
            '7': 'Jul',
            '8': 'Aug',
            '9': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec',
          };
          return monthMapping[val] || '';
        },
        font: {
          size: 16,
        },
        ...DEFAULT_X_TICK_COLOR_AND_FONT,
        autoSkip: false,
        maxTicksLimit: 13,
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        callback: function (val: number) {
          return val.toString();
        },
        font: {
          size: 16,
        },
        stepSize: 1,
        ...DEFAULT_TICK_COLOR,
      },
      title: {
        display: true,
        text: 'MWh',
        font: {
          weight: 'bold',
        },
      },
      grid: {
        display: true,
      },
    },
  },

  datasets: {
    bar: {
      barPercentage: 1.0,
      categoryPercentage: 0.3,
    },
  },
};
