import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { PlannedOutage } from 'src/app/shared/models/plannedOutage';
import { Site } from 'src/app/shared/models/site';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { FileService } from 'src/app/shared/services/utils/file.service';
import { addDays, endOfMonth, intervalToDuration, startOfMonth } from 'date-fns';
import { UrlService } from 'src/app/services/url.service';
import { AllowedAppRoleEnum } from 'src/app/core/enums/roles.enum';
import { DateChangedEvent } from 'src/app/shared/components/month-selector/month-selector.component';
import { PlannedOutageService } from 'src/app/shared/services/planned-outage.service';
import { Router } from '@angular/router';
import { SitesService } from 'src/app/shared/services/api/sites.service';

const NO_EDIT_COLUMNS = [
  'startDateTime',
  'derateAmount',
  'temporaryExpectedCapacity',
  'temporaryCapacity',
  'timeStart',
  'restorationTime',
  'duration',
  'createdBy',
  'lastUpdatedBy',
];
const HAS_EDIT_COLUMNS = [...NO_EDIT_COLUMNS, 'edit'];

@Component({
  selector: 'app-site-planned-outages',
  templateUrl: './site-planned-outages.component.html',
  styleUrls: ['./site-planned-outages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SitePlannedOutagesComponent extends BaseComponent {
  public readonly allowUserManagePlannedOutages = [
    AllowedAppRoleEnum.PLANNED_OUTAGES_FORM_READ_WRITE,
  ];
  // Earliest PROD data available
  public readonly minDate = new Date('01/01/2022');
  public readonly maxDate = addDays(new Date(), 1);

  @Input() set site(site: Site) {
    if (site) {
      this.siteModel = site;
      // TODO Assume this should be integrated into GTM.
      this.siteNameForGa = GtmService.format(this.siteModel.name);
    }
  }

  @Input() set outages(outages: PlannedOutage[]) {
    if (outages) {
      this.outagesModel = outages;
      this.dataSource.data = outages;
    } else {
      this.outagesModel = [];
      this.dataSource.data = [];
    }
  }

  @Input() set showEdit(isShow: boolean) {
    this.isEdit = isShow;
    if (isShow) {
      this.displayedColumns = HAS_EDIT_COLUMNS;
    } else {
      this.displayedColumns = NO_EDIT_COLUMNS;
    }
  }
  currentDate = new Date().toISOString();
  dataSource = new MatTableDataSource<PlannedOutage>([]);
  displayedColumns = [];
  isEdit = false;
  paginatorSizeOptions = [5, 10, 25, 50, 100];
  outagesModel: PlannedOutage[];
  siteModel: Site;
  siteNameForGa: string;

  constructor(
    public fileService: FileService,
    public plannedOutageService: PlannedOutageService,
    public urlService: UrlService,
    private router: Router,
    public sitesService: SitesService
  ) {
    super();
  }

  newMonthSelection(selection: DateChangedEvent) {
    const proto = new Date(parseInt(selection.year), selection.monthIndex);
    const st = startOfMonth(proto);
    const end = endOfMonth(proto);
    this.plannedOutageService
      .getSitePlannedOutages(this.siteModel.unique_id, st, end)
      .subscribe((outages) => {
        const dt = outages.map((o) => new PlannedOutage(o));
        this.dataSource.data = dt;
      });
    // GTM External Doc 5.3.4.1.1  TODO is this the proper GTM event?
    GtmService.clickEvent({
      category: 'sites',
      action: 'date-range-changed',
      label: `date-|-${this.siteModel.unique_id}`,
    });
  }

  asDuration(outage: PlannedOutage) {
    const span = intervalToDuration({
      start: outage.startDateTime,
      end: outage.endDateTime,
    });
    let days = 0;
    if (span.days) {
      days = span.days * 24;
    }
    const hrs = `${span.hours + days}`.padStart(2, '0');
    const mins = `${span.minutes}`.padStart(2, '0');
    return `${hrs}:${mins}`;
  }

  protected readonly GtmService = GtmService;

  createNew() {
    this.sitesService.specificSite$.next(this.siteModel.unique_id);
    void this.router.navigateByUrl('/action-center/planned-outages');
  }
}
