import { Component, OnInit, Inject, ElementRef, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BarElement, Chart, ChartDataset, ChartOptions } from 'chart.js';
import { DEFAULT_CURTAILMENT_CHART_OPTIONS } from './default-curtailment-chart-options';
import { CurtailmentChartModel, SitesService } from 'src/app/shared/services/api/sites.service';
import { Axis, getDataset } from 'src/app/shared/utils/time-cartesian-chart.util';
import { ChartBase } from 'src/app/shared/models/charts/chart-base';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

enum CurtailmentChartDatasetLabel {
  NON_RELIABILITY = 'Non-Reliability',
  RELIABILITY = 'Reliability',
  UNDER_REVIEW = 'Under Review',
}
export interface CurtailmentChartData {
  nonReliability: Axis[];
  reliability: Axis[];
  underReview: Axis[];
}

@Component({
  selector: 'app-curtailment-chart',
  templateUrl: './curtailment-chart.component.html',
  styleUrls: ['./curtailment-chart.component.scss'],
})
export class CurtailmentChartComponent
  extends ChartBase<CurtailmentChartModel, CurtailmentChartDatasetLabel>
  implements OnInit
{
  public years = [];
  private _currentYear: string;
  public yearControl = new FormControl<string>('2024');
  public chart: any;
  public formattedMonth: number;
  public chartOptions: ChartOptions = DEFAULT_CURTAILMENT_CHART_OPTIONS;
  private _chartData: CurtailmentChartData;
  protected readonly chartId = 'curtailmentChart';
  protected readonly chartType = 'bar';
  protected readonly gtmLabel = 'curtailment';
  protected readonly gtmCategory = 'todo';
  private _siteId: string;
  loading = false;
  constructor(
    public themeService: ThemeService,
    public siteService: SitesService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) protected document: Document,
    protected elRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super(themeService, document, elRef, renderer);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((response) => {
        if (response['unique_id']) {
          this._siteId = response['unique_id'] as string;
        }
      })
    );
    const today = new Date();
    const endYear = today.getFullYear();
    const startYear = endYear - 2;
    for (let i = startYear; i <= endYear; i++) {
      this.years.push(i.toString());
    }
    this._currentYear = today.getFullYear().toString();
    this.yearControl.setValue(this._currentYear);
    this.siteService
      .getCurtailmentDetails(this._siteId, this.yearControl.value)
      .subscribe((payload) => {
        this.convertPayloadToChartData(payload);
        this.defineDatasets();
        this.hidePreviouslyHiddenData();
      });
    super.ngOnInit();
    Chart.register(BarElement);
  }
  public toggleDataset(dataset: ChartDataset): void {
    // GTM DOC - 5.3.4.1, 5.2.5.1
    GtmService.clickEvent({
      category: this.gtmCategory,
      label: this.gtmLabel,
      action:
        'axis-|-' +
        GtmService.format(dataset.label) +
        '-|-' +
        (dataset.data.length > 0 ? 'off' : 'on'),
    });

    if (dataset.data.length === 0) {
      dataset.data = this.getChartDataFromLabel(dataset.label as CurtailmentChartDatasetLabel);
      this._hiddenDatasets.splice(this._hiddenDatasets.indexOf(dataset.label), 1);
    } else {
      dataset.data = [];
      this._hiddenDatasets.push(dataset.label);
    }

    this.renderChart();
  }
  protected defineDatasets(): void {
    const tmpData = [];
    if (!this._chartData) {
      return;
    }
    if (this._chartData.nonReliability?.length > 0) {
      tmpData.push(
        getDataset({
          color: 'apple_green',
          hoverBackgroundColor: 'apple_green',
          data: this._chartData.nonReliability,
          label: CurtailmentChartDatasetLabel.NON_RELIABILITY,
        })
      );
    }
    if (this._chartData.reliability?.length > 0) {
      tmpData.push(
        getDataset({
          color: 'navy_blue',
          hoverBackgroundColor: 'navy_blue',
          data: this._chartData.reliability,
          label: CurtailmentChartDatasetLabel.RELIABILITY,
        })
      );
    }
    if (this._chartData.underReview?.length > 0) {
      tmpData.push(
        getDataset({
          color: 'light_grey',
          hoverBackgroundColor: 'light_grey',
          data: this._chartData.underReview,
          label: CurtailmentChartDatasetLabel.UNDER_REVIEW,
        })
      );
    }
    this.datasets.set(this, tmpData as ChartDataset[]);
  }
  protected getChartDataFromLabel(label: CurtailmentChartDatasetLabel): Axis[] {
    switch (label) {
      case CurtailmentChartDatasetLabel.NON_RELIABILITY:
        return this._chartData.nonReliability;
      case CurtailmentChartDatasetLabel.RELIABILITY:
        return this._chartData.reliability;
      case CurtailmentChartDatasetLabel.UNDER_REVIEW:
        return this._chartData.underReview;
      default:
        return [];
    }
  }
  protected convertPayloadToChartData(payload: CurtailmentChartModel[]) {
    // this._logger.debug('Receiving source comparison data for chart of size', payload.length);
    if (payload.length === 0) {
      this._chartData = undefined;
      return;
    }

    const chartData: CurtailmentChartData = {
      nonReliability: [],
      reliability: [],
      underReview: [],
    };

    payload.forEach((item) => {
      const date = moment(item.month, 'YYYY-MM');
      const month = date.format('MM');
      this.chartOptions.scales.x['labels'] = month;
      this.formattedMonth = Number(month);
      if (item.reliability_curtailment) {
        const reliabilityCurtailment = item.reliability_curtailment / 1000;
        chartData.reliability.push({ x: this.formattedMonth, y: reliabilityCurtailment });
      }
      if (item.economic_curtailment) {
        const economicCurtailment = item.economic_curtailment / 1000;
        chartData.nonReliability.push({ x: this.formattedMonth, y: economicCurtailment });
      }
      if (item.uncertain_curtailment) {
        const uncertainCurtailment = item.uncertain_curtailment / 1000;
        chartData.underReview.push({ x: this.formattedMonth, y: uncertainCurtailment });
      }
      this._chartData = chartData;
    });
  }
  selectYear(event) {
    this.loading = true;
    this.siteService
      .getCurtailmentDetails(this._siteId, event.value as string)
      .subscribe((data) => {
        this.convertPayloadToChartData(data);
        this.defineDatasets();
        this.hidePreviouslyHiddenData();
        this.loading = false;
      });
  }
}
