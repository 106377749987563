import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { addDays, format, isToday } from 'date-fns';
import {
  Chart,
  ChartDataset,
  ScatterDataPoint,
  ChartOptions,
  LinearScale,
  TimeScale,
  LineController,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js';
import { DOCUMENT } from '@angular/common';
import { Axis, getDataset } from 'src/app/shared/utils/time-cartesian-chart.util';
import { InterconnectionGenerationModel } from 'src/app/shared/models/interconnection';
import 'chartjs-adapter-date-fns';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';
import { SitesService } from 'src/app/shared/services/api/sites.service';
import { GenerationDataService } from 'src/app/shared/services/generation-data.service';
import { debounceTime, skip } from 'rxjs/operators';
import { GenerationModel, GenerationSummaryModel } from 'src/app/shared/models/generation';
import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_X_TICK_COLOR_AND_FONT,
  DEFAULT_GRID,
  DEFAULT_TICK_COLOR,
} from 'src/app/shared/models/charts/default-chart-options';
import { compareByProperty } from 'src/app/shared/utils/object.util';
import { AppConfig } from 'src/app/core/config/app.config';
import { ChartBase } from 'src/app/shared/models/charts/chart-base';

const TIME_FORMAT_LAST_GENERATION = 'MM/dd/yyyy HH:mm a';

export interface SolarGenerationChart {
  generationActual: Axis[];
  mdmMwh: Axis[];
}

enum SiteGenerationDatasetLabel {
  ACTUAL_PRODUCTION = 'Solar Generation',
  MDM_MWH = 'Solar Generation ', // Need the space so that when the line is toggled off/on, MDM does show again
}

@Component({
  selector: 'app-site-generation-chart',
  templateUrl: './site-generation-chart.component.html',
  styleUrls: ['./site-generation-chart.component.scss'],
})
export class SiteGenerationChartComponent
  extends ChartBase<InterconnectionGenerationModel, SiteGenerationDatasetLabel>
  implements OnDestroy
{
  @Output() dateSelection = new EventEmitter<Date>();

  @Input() set uniqueId(uniqueId: number) {
    this._siteId = uniqueId;
    this.generationService.getSiteSpecificGenerationSummary(this._siteId);

    this.registerChartComponents();
    this.subscribeToThemeChanges();
    this.subscribeToDataUpdates();
  }

  @Input() set generationData(generation) {
    if (generation) {
      this._logger.info(`Setting generation data ${generation.data.length}`);
      this.generation = generation.data;
      this.updateSummary({
        live: generation.live,
        recloser: generation.recloser_status,
        comm: generation.comm_status,
      });
      this.defineDatasets();
      this.renderChart();
    }
  }

  public readonly minDate = new Date('01/01/2022');
  public readonly maxDate = new Date();
  public readonly streamingData =
    'This data is live streaming data and not revenue quality. This data may not accurately reflect what is shown on your Revenue Invoice.';

  protected readonly chartId = 'energyProductionChart';
  protected readonly chartType = 'line';
  protected readonly gtmCategory = 'sites';
  protected gtmLabel = '';
  protected showSummary = true;

  private _chartData: WeakMap<SiteGenerationChartComponent, SolarGenerationChart>;
  public generation: InterconnectionGenerationModel[];
  public summary: GenerationSummaryModel = null;

  public chartOptions: ChartOptions = {
    ...DEFAULT_CHART_OPTIONS,
    //     Uncomment for some fun times.
    // animations: {
    //   tension: {
    //     duration: 2000,
    //     easing: 'easeInSine',
    //     from: -2,
    //     to: 2,
    //     loop: true
    //   }
    // },
    scales: {
      x: {
        type: 'time' as const,
        time: {
          unit: 'hour' as const,
        },
        ticks: {
          ...DEFAULT_X_TICK_COLOR_AND_FONT,
        },
        ...DEFAULT_GRID,
      },
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          ...DEFAULT_TICK_COLOR,
        },
        title: {
          display: true,
          text: 'MW',
          font: {
            weight: 'bold',
          },
        },
        ...DEFAULT_GRID,
      },
    },
  };

  public get lastGenerationTime() {
    return format(new Date(), TIME_FORMAT_LAST_GENERATION);
  }

  private _selectedDate: Date = new Date();
  private readonly _logger = new LoggingService('EnergyChart', 'charts');
  protected _hiddenDatasets: string[] = [];
  private _siteId: number;

  constructor(
    private appConfig: AppConfig,
    public themeService: ThemeService,
    protected sitesService: SitesService,
    private generationService: GenerationDataService,
    @Inject(DOCUMENT) protected document: Document,
    protected elRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super(themeService, document, elRef, renderer);
    this._chartData = new WeakMap();
    const initData = {
      generationActual: [],
      mdmMwh: [],
    };
    this._chartData.set(this, initData); // don't leave undefined
  }

  private registerChartComponents() {
    Chart.register(
      LinearScale,
      TimeScale,
      LineController,
      CategoryScale,
      PointElement,
      LineElement,
      Tooltip
    );
  }

  private subscribeToThemeChanges() {
    if (!this._siteId) {
      return;
    }
    this.subscriptions.push(
      this.themeService.themeChanges$.subscribe((theme) => this.setChartOptionsTheme(theme))
    );
  }

  private subscribeToDataUpdates() {
    if (!this._siteId) {
      return;
    }
    this.subscriptions.push(
      this.generationService.generationSummary$.pipe(debounceTime(500)).subscribe((summary) => {
        this.updateSummary(summary);
      }),
      this.generationService.generationData$
        .pipe(skip(1)) // Skipping the first emission as it may be initial or default data
        .subscribe((data) => this.renderGenerationData(data))
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._chart) {
      // seeing old chart not clearing on reloads so ensure its destruction
      this._chart.destroy();
      delete this._chart;
    }
  }

  defineDatasets(): void {
    if (this.generation) {
      const _chartData = this.convertPayloadToChartData(this.generation);

      this._chartData.set(this, _chartData); // don't leave undefined

      let tmpData: ChartDataset[] = [];
      if (this.checkMDMIsPopulated(_chartData.mdmMwh)) {
        // Even though we are displaying MDM data, Red requested it look like the actual generation on the chart
        // so the same label and color
        tmpData = [
          getDataset({
            color: 'orange',
            data: _chartData.mdmMwh,
            label: SiteGenerationDatasetLabel.MDM_MWH,
          }),
        ];

        console.log(_chartData);
        console.log(tmpData);
      } else {
        tmpData = [
          getDataset({
            color: 'orange',
            data: _chartData.generationActual,
            label: SiteGenerationDatasetLabel.ACTUAL_PRODUCTION,
          }),
        ];
      }

      this.datasets.set(this, tmpData);
      this.hidePreviouslyHiddenData();
    }
  }

  // Show MDM data if we have data for that today
  // and that at least 5 data points are populated with data that is not 0
  public checkMDMIsPopulated(dataArrayMDM: Axis[]): boolean {
    return (
      dataArrayMDM.some((axis) => axis.y !== undefined) &&
      dataArrayMDM.filter((axis) => axis.y > 0).length >= 5
    );
  }

  public toggleDataset(dataset: ChartDataset): void {
    // GTM DOC - 5.8.4.4.2.1
    // GTM External Doc - 5.3.4.2.1
    const label = dataset.label;
    GtmService.clickEvent({
      category: this.gtmCategory,
      action: `axis-|-${GtmService.format(label)}-|-${dataset.data.length > 0 ? 'off' : 'on'}`,
      label: `solar-generation-|-${this._siteId}`,
    });

    if (dataset.data.length === 0) {
      dataset.data = this.getChartDataFromLabel(label);
      this._hiddenDatasets.splice(this._hiddenDatasets.indexOf(label), 1);
    } else {
      dataset.data = [];
      this._hiddenDatasets.push(label);
    }

    this.renderChart();
  }

  protected getChartDataFromLabel(label: string) {
    const chartData = this._chartData.get(this);
    switch (label) {
      case SiteGenerationDatasetLabel.ACTUAL_PRODUCTION:
        return chartData.generationActual;
      case SiteGenerationDatasetLabel.MDM_MWH:
        return chartData.mdmMwh;
      default:
        return [];
    }
  }

  convertPayloadToChartData(payload: InterconnectionGenerationModel[]): SolarGenerationChart {
    const _chartData: SolarGenerationChart = {
      generationActual: [],
      mdmMwh: [],
    };

    // From midnight last night to midnight tonight
    const start = this._selectedDate;
    start.setHours(0, 0, 0, 0);
    const startMsSinceEpoch = start.getTime();
    const end = addDays(start, 1);

    this.chartOptions.scales.x['min'] = startMsSinceEpoch;
    this.chartOptions.scales.x['max'] = end.getTime();

    payload?.forEach((g) => {
      const genTimeMsSinceEpoch = new Date(g.dt_utc).getTime();
      _chartData.generationActual.push({
        x: genTimeMsSinceEpoch,
        y: this.normalizeDatum(g.active_power),
      });

      if (g.mdm_mwh !== undefined) {
        _chartData.mdmMwh.push({
          x: genTimeMsSinceEpoch,
          y: this.normalizeDatum(g.mdm_mwh),
        });
      }
    });

    if (this.checkMDMIsPopulated(_chartData.mdmMwh)) {
      _chartData.generationActual = [];
    }

    return _chartData;
  }

  setChartOptionsTheme(isDarkTheme = false): void {
    const newOptions = Object.assign({}, this.chartOptions);
    for (const scale in newOptions.scales) {
      if (isDarkTheme) {
        newOptions.scales[scale]['grid'] = {
          color: 'rgba(255, 255, 255, 0.2)',
        };

        if (newOptions['scales'][scale]['ticks']) {
          newOptions['scales'][scale]['ticks']['color'] = '#ffffffb3';
        } else {
          newOptions['scales'][scale]['ticks'] = {};
          newOptions['scales'][scale]['ticks']['color'] = '#ffffffb3';
        }

        if (newOptions['scales'][scale]['title']) {
          newOptions['scales'][scale]['title']['color'] = 'white';
        }
      } else {
        if (newOptions['scales'][scale]['grid']) {
          delete newOptions['scales'][scale].grid;
          delete newOptions['scales'][scale]['ticks'].color;
        }

        if (newOptions['scales'][scale]['title']) {
          newOptions['scales'][scale]['title']['color'] = 'gray';
        }
      }
    }

    this.chartOptions = newOptions;
    this.renderChart();
  }

  private renderGenerationData(data: GenerationModel[]) {
    if (!this.datasets.get(this)) {
      return;
    }

    // Helper in translation - take our old value and update by generationData's values, as
    //  generationData is not where we receive our first piece of data
    data.forEach((d) => {
      const matchPower = this.datasets
        .get(this)[0]
        .data.find((dData) => new Date(d.dt_utc).getTime() === (dData as ScatterDataPoint).x);

      if (matchPower) {
        Object.assign(matchPower, {
          x: new Date(d.dt_utc).getTime(),
          y: d.active_power.actual,
        });
      } else {
        this.datasets.get(this)[0].data.push({
          x: new Date(d.dt_utc).getTime(),
          y: d.active_power.actual,
        });
      }
    });

    // Sort by dt_utc, just in case we get a previously-missing dt_utc value
    this.datasets.get(this)[0].data.sort((a, b) => compareByProperty(a, b, 'x'));

    this.renderChart();
  }

  /**
   * Output from dateselector component.
   * @param date
   */
  public dateChanged(date: Date): void {
    // GTM DOC - 5.8.4.4.1
    // GTM External DOC - 5.3.4.1.1
    GtmService.clickEvent({
      category: 'sites',
      action: 'date-range-changed',
      label: `date-|-${this._siteId}`,
    });
    this._selectedDate = date ?? new Date();

    this.showSummary = isToday(this._selectedDate);
    this.dateSelection.emit(this._selectedDate);
  }

  public normalizeDatum(datum: number) {
    if (datum >= 0) {
      return datum;
    } else if (datum < 0) {
      return 0;
    }
    return datum;
  }

  /**
   * Keeps selected legend items hidden after new data gets loaded
   * @private
   */
  protected hidePreviouslyHiddenData(): void {
    for (const label of this._hiddenDatasets) {
      const data = this.datasets.get(this);
      const tmp = data.find((ds) => ds.label === label);
      if (tmp) {
        tmp.data = [];
      }
    }
    this.renderChart();
  }

  private updateSummary(payload: GenerationSummaryModel): void {
    if (this.summary === null && payload !== null) {
      // If summary is null, and payload is set, set summary to new payload
      this.summary = payload;
    } else if (this.summary !== null && payload !== null) {
      // If summary has already been set and there's a new provided payload. Update summary with new changes.
      this.summary = { ...this.summary, ...payload };
    }

    if (this.summary !== null) {
      Object.keys(this.summary).forEach((k) => {
        if (typeof this.summary[k] === 'number') {
          this.summary[k] = this.normalizeDatum(this.summary[k] as number);
        }
      });
    }
  }
}
